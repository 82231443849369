import adImg from '../../assets/img/genti-ad-image.png'
import { UploadList, MonetizeCommunity, Listening } from './Icons'

export const Section2 = () => {
    return (
        <div className='section-2-landing-page'>
            <div className='landing-page-sect2-head'>
                <p>How Genti Works for Creators</p>
            </div>

            <div className='landing-page-sect-2-cards'>
                <div class="card-gradient-border">
                    <div class="card-content">
                        {/* <!-- Your icon or image here --> */}
                        <UploadList />
                        
                        <div>
                            <h3>Upload &amp; List</h3>
                            <p>
                            Share your audiobooks, poetry, comedy specials, or audio plays via the
                            self service Genti Creator Portal with the world.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-gradient-border">
                    <div class="card-content">
                        {/* <!-- Your icon or image here --> */}
                        <MonetizeCommunity />
                        
                        <div>
                            <h3>Monetize Your Community</h3>
                            <p>
                                Sell your audio content in the Genti Creator Marketplace, 
                                set your own pricing, or offer it for free. Listeners can 
                                even pay per chapter for more flexibility.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-gradient-border">
                    <div class="card-content">
                        {/* <!-- Your icon or image here --> */}
                        <Listening />
                        
                        <div>
                            <h3>Seamless Listening Experience</h3>
                            <p>
                                Give your listeners a secure, high-quality experience offline or 
                                online. We support offline and in-app downloads while protecting 
                                your intellectual property with Digital Rights Management (DRM)
                                technology
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='landing-page-sect2-end'>
                <div>
                    <img src={adImg} alt='Ad Image'/>
                </div>

                <div className='landing-sect-2-end-content'>
                    <div>
                        <h2>Get Discovered by New Listeners</h2>
                        <p>
                            Reach a growing 22k+ audience of existing Genti listeners 
                            searching for audiobooks and stories in African voices, accents, 
                            and languages. Your content is featured through in-app categories
                            and our recommendations engine.
                        </p>
                    </div>

                    <div className='sect2-end-flex-section'>
                        <div>
                            <h4>Track Listens and Withdraw Earnings in Real-Time</h4>
                            <p>
                                Earnings go straight into your Naira & Dollar Wallets 
                                with easy withdrawals to your bank account.
                            </p>
                        </div>
                        <div>
                            <h4>Create & Sleep Well </h4>
                            <p>
                                We handle content security, listener experience, and 
                                continuous platform improvements—so you can focus on 
                                your craft.
                            </p>
                        </div>
                    </div>

                    <div>
                        <button>
                            Get Started
                            <span>&#x203A;</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
