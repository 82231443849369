import React, { useState } from 'react'
import backArrow from '../../../../assets/img/backArrow.svg'
import MP3 from '../MP3'
import { useNavigate } from 'react-router-dom'
const RSSUpload = ({ setSection }) => {
    const navigate = useNavigate()
    const [contentType, setContentType] = useState('RSS_Upload')

    if (contentType === 'MP3') return <MP3 />
    return (
        <div>
            <p className='page-title'>Content/<span className='page-sub-title text-white'>Upload Content</span></p>

            <div className='mb-4 d-flex align-items-center'>
                <div
                    style={{
                        marginRight: '9px'
                    }}
                    onClick={() => {
                        navigate('/dashboard/content')
                    }}
                    role='button'

                >
                    <img src={backArrow} alt='icon' />
                </div>
                <h4 className='page-details mb-0'>Upload Content</h4>

            </div>

            <div className="third-row analytics-card content-table withdraw rss-upload--form__wrapper no-side__padding">
                <div className='side-padding'>
                    <div className='d-flex align-items-center content-select__wrapper'>
                        <p className='content-select__text mb-0 '>What is the format of your existing audio?</p>
                        <div className='content-select_form '>
                            <label className="radio_label">
                                <input value={'RSS_Upload'} className='content-select_input' name="content-type"
                                    checked={contentType === 'RSS_Upload'}
                                    type="radio" onChange={(e) => {
                                        setContentType(e.target.value)

                                    }} />
                                <span className='content-type__select'>RSS</span>
                            </label>
                            <label className="radio_label">
                                <input value={'MP3'}
                                    className='content-select_input'
                                    checked={contentType === 'MP3'}
                                    name="content-type"
                                    type="radio"
                                    onChange={(e) => {
                                        setContentType(e.target.value)

                                    }} />
                                <span className='content-type__select'>MP3 file</span>
                            </label>
                        </div>
                    </div>
                </div>


                <div>
                    <form className='upload-content__form rss-input__form '>
                        <div className='form_container side-padding no-border'>
                            <label className=''>Enter RSS</label>
                            <input className='form-control' placeholder='RSS link' />
                        </div>
                        <div className='d-flex justify-content-end rss-upload-button__wrapper'>
                            <div className='side-padding'>
                                <button className='btn auth-btn ' type='submit' onClick={() => {
                                    setSection('RSS_Content')
                                }}>
                                    {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
                                    Upload


                                </button>
                            </div>


                        </div>

                    </form>
                </div>
            </div>

        </div>

    )
}

export default RSSUpload