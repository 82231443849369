import overviewIcon from '../assets/img/overview.svg'
import contentIcon from '../assets/img/content.svg'
import transactionIcon from '../assets/img/transaction.svg'
import transactIcon from '../assets/img/transact.svg'

import reportIcon from '../assets/img/report.svg'
import activeGuideIcon from '../assets/img/active_guide.svg'
import inActiveGuideIcon from '../assets/img/inactive_guide.svg'
import inactiveOverviewIcon from '../assets/img/inactiveHome.svg'
import activeContentIcon from '../assets/img/activeContent.svg'
import activeReport from '../assets/img/activeReport.svg'





const SidebarMenu = [
    {
        name: 'Overview',
        icon: overviewIcon,
        inactiveIcon: inactiveOverviewIcon,
        link: 'dashboard'
    },
    {
        name: 'Content',
        icon: activeContentIcon,
        inactiveIcon: contentIcon,
        link: 'dashboard/content'
    },
    {
        name: 'Report',
        icon: activeReport,
        inactiveIcon: reportIcon,
        link: 'dashboard/report'
    },
    {
        name: 'Transactions',
        icon: transactIcon,
        inactiveIcon: transactionIcon,
        link: 'dashboard/transactions'
    }
    // {
    //     name: 'Settings',
    //     icon: settingsIcon,
    //     inactiveIcon: settingsIcon,
    //     link: 'settings'
    // },
    // {
    //     name: 'Guide',
    //     icon: activeGuideIcon,
    //     inactiveIcon: inActiveGuideIcon,
    //     link: 'guide'
    // }
]

export default SidebarMenu