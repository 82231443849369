import React, { useState } from 'react'
// import ContentForm from './ContentForm'
import Copyright from './Copyright'
// import Client from '../../../../client'
// import { toast } from 'react-toastify';
// import { useMutation } from 'react-query';
// import ButtonLoader from '../../../../components/ButtonLoader'
import { useNavigate, useParams } from 'react-router-dom'
import AddChapterForm from './AddChapterForm'
import { useQuery } from 'react-query'
import Client from '../../../client'
const AddChapter = () => {
    const [section, setSection] = useState('Content_upload')
    const [content, setContent] = useState()
    const navigate = useNavigate()
    const params = useParams()
    const { id } = params
    const { data: contents, isLoading: loading } = useQuery(
        ["content_details"],
        () => Client.content.single_content({ id: id }),
        {
          onSuccess: (data) => {
            setContent(data?.data?.[0])
          },
          onError: (err) => {},
          enabled: Boolean(id),
        }
      );
    // const { mutate: editMetaData, isLoading } = useMutation(Client.content.upload_metadata, {
    //     onSuccess: (data) => {
    //         if (data.error) {
    //             toast.error(data.error.message)
    //         } else {
    //             toast.success('Content updated succesfully')
    //             // setSection('Copyright')
    //             navigate('/dashboard/content')
    //         }
    //     },
    //     onError: (error) => {
    //         if (error.response) return toast.error(error.response.data.error.message)
    //         else if (error.data) return toast.error(error.data.message)
    //         else return toast.error(error.message)
    //     }
    // })
    return (
            <>
                {section === 'Content_upload' && <AddChapterForm setSection={setSection} title={content}/>}
                {section === 'Copyright' && <Copyright setSection={setSection} previousSection={'Content_form'} />}
            </>
    )
}
export default AddChapter