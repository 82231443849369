import SellDirectly from '../../assets/img/sell-directly.png'
import TrackAndWithDraw from '../../assets/img/track-and-withdraw.png'
import PremiumListening from '../../assets/img/premium-listening.png'
import SeamlessListening from '../../assets/img/seamless-listening.png'
import SecureIP from '../../assets/img/secure-ip.png'
import EarnInCurrency from '../../assets/img/earn-in-naira-and-dollars.png'

const cardData = [
    {
        image: SellDirectly,
        title: "Sell Directly, No Middlemen",
        textContent: "Upload your content, set your price, or offer it for free.",
        alt: 'Sell Directly'
    },
    {
        image: EarnInCurrency,
        title: "Earn in Naira & Dollars",
        textContent: "Get paid easily, with instant Naira withdrawals and Dollar withdrawals on request.",
        alt: 'Earn In Currency'
    },
    {
        image: SecureIP,
        title: "Secure Your IP",
        textContent: "Your audio is DRM-protected with in-app downloads only, preventing piracy",
        alt: 'Secure IP'
    },
    {
        image: SeamlessListening,
        title: "Offer Seamless Offline Listening",
        textContent: "Listeners enjoy your content anytime, anywhere, even without internet",
        alt: 'Seamless Listening'
    },
    {
        image: PremiumListening,
        title: "Deliver a Premium Experience",
        textContent: "We optimize playback to ensure high-quality audio for your audience",
        alt: "Premium Listening"
    },
    {
        image: TrackAndWithDraw,
        title: "Track & Withdraw Earnings in Real Time",
        textContent: "Monitor your sales and withdraw with ease.",
        alt: "Track and Withdraw"
    }
]

export const Section3 = () => {
    return (
        <div className='section-3-landing-page text-white'>
            <div className='landing-page-sect3-head'>
                <p>Why Choose Genti</p>
            </div>

            <div className='landing-sect-3-cards'>
                {
                    cardData.map((card, i) => (
                        <div key={i} className='sect-3-card'>
                            <div>
                                <img src={card.image} alt={card.alt} />
                            </div>
                            <h5>{card.title}</h5>
                            <p>
                                {card.textContent}
                            </p>
                        </div>
                    ))
                }
            </div>
           
        </div>
    )
}