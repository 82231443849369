import React from 'react'
import { Modal } from 'reactstrap'
import Success from '../../../components/SuccessAnimation'
import { useNavigate } from 'react-router-dom'

const TransactionSuccesful = ({ isOpen, toggle }) => {
    const navigate = useNavigate()
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className='modal-dialog-centered '
            contentClassName="upload-success__modal_content-wrapper  "
        >
            <div className='analytics-card transaction__modal position-relative'>
                <form className=''>
                    <div className='animation-wrapper'>
                        <Success />
                    </div>
                    <p className='congrats-text'>Congratulations! </p>
                    <p className='congrats-sub-text'>Your withdrawal was successful. Your account will be credited shortly</p>
                    <div className='sign-in-btn'>
                        <button className='btn auth-btn w-100 ' onClick={(e) => {
                            e.preventDefault()
                            navigate("/dashboard/transactions")
                        }}>
                            Done
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default TransactionSuccesful