import React, { useState } from "react";
// import AppLayout from '../../layout/App'
import { Row, Col } from "reactstrap";
import Chart from "../../components/Chart";
import EmptyChart from "../../components/Chart/EmptyState";

import SparkLine from "../../components/Chart/SparkLines.js";
import Progress from "../../components/ProgressBar";
import nairaIcon from "../../assets/img/naira.svg";
import arrowIcon from "../../assets/img/cardArrow.svg";
// import t1 from '../../assets/img/t11.svg'
// import t2 from '../../assets/img/t12.svg'
// import t3 from '../../assets/img/t13.svg'
// import t4 from '../../assets/img/t14.svg'
// import l0 from '../../assets/img/l0.svg'
// import l1 from '../../assets/img/l1.svg'
// import l2 from '../../assets/img/l2.svg'
// import l3 from '../../assets/img/l3.svg'
// import l4 from '../../assets/img/l4.svg'
import filter from "../../assets/img/filter.svg";
// import tIcon from '../../assets/img/tIcon.svg'
import starIcon from "../../assets/img/star.svg";
import stockIcon from "../../assets/img/stock.svg";
import Cookies from "js-cookie";
import DatePicker from "../../components/RangePicker";
import moment from "moment";
import close from "../../assets/img/dClose.svg";
import { useQuery } from "react-query";
import Client from "../../client";
import { formatter, formatDateString } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
// import { findFlagUrlByCountryName } from "country-flags-svg";
import noImage from "../../assets/img/no-image.png";
import truncate from "truncate";

const Dasboard = () => {
  const navigate = useNavigate();
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [showDateValue, setDateValue] = useState(false);
  const currentUser = JSON.parse(Cookies.get("CREATOR_CURRENT_USER"));

  const { data } = useQuery(
    "dashboard_details",
    Client.dashboard.dashboard_details
  );
  const { data: releases } = useQuery(
    "top_releases",
    Client.dashboard.top_releases
  );
  const { data: transactions } = useQuery(
    "dashboard_transactions",
    Client.dashboard.recent_transactions
  );

  return (
    // <AppLayout>
    <div className="">
      <div className="mb-4 d-block d-lg-flex justify-content-between align-items-center">
        <div>
          <div className="d-flex d-lg-block align-items-center justify-content-between w-100 mb-3">
            <p className="page-title">Overview</p>
            <div className="d-none d-lg-none position-relative">
              {!showDateValue ? (
                <div
                  className="filter-wrapper d-flex align-items-center"
                  role="button"
                  onClick={() => {
                    setShowCalendar(!showCalendar);
                  }}
                >
                  <p className="filter_text mb-0">Filter by Date</p>
                  <div>
                    <img src={filter} alt="icon" />
                  </div>
                </div>
              ) : (
                <div
                  className="filter-wrapper d-flex align-items-center position-relative"
                  role="button"
                  onClick={() => {
                    setShowCalendar(true);
                    setShowCalendar(false);
                  }}
                >
                  <p className="filter_text mb-0">{`${moment(startDate).format(
                    "L"
                  )} - ${moment(endDate).format("L")}`}</p>
                  <div
                    style={{
                      marginLeft: "18px",
                    }}
                    role="button"
                    onClick={() => {
                      setDateValue(false);
                    }}
                  >
                    <img src={close} alt="icon" height="12px" width="12px" />
                  </div>
                </div>
              )}

              {showCalendar && (
                <div className="position-absolute calendar-input_wrapper">
                  {/* <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                setDateValue={setDateValue}
                                setShowCalendar={setShowCalendar}

                            /> */}
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setDateValue={setDateValue}
                    setShowCalendar={setShowCalendar}
                  />
                </div>
              )}
            </div>
          </div>
          <h4 className="dashboard-greeting text-capitalize">{`Welcome ${
            currentUser?.name.split(" ")[1] ?? currentUser?.name
          }`}</h4>
          <p className="greeting-details">Here's how you are doing on Genti</p>
          <p className="greeting-details">
            Last statement date:{" "}
            {data?.data?.last_statement_date
              ? formatDateString(data?.data?.last_statement_date)
              : "No royalties shared yet"}
          </p>
        </div>
        <div className="d-none d-lg-none position-relative">
          {!showDateValue ? (
            <div
              className="filter-wrapper d-flex align-items-center"
              role="button"
              onClick={() => {
                setShowCalendar(!showCalendar);
              }}
            >
              <p className="filter_text mb-0">Filter by Date</p>
              <div>
                <img src={filter} alt="icon" />
              </div>
            </div>
          ) : (
            <div
              className="filter-wrapper d-flex align-items-center position-relative"
              role="button"
              onClick={() => {
                setShowCalendar(true);
                setShowCalendar(false);
              }}
            >
              <p className="filter_text mb-0">{`${moment(startDate).format(
                "L"
              )} - ${moment(endDate).format("L")}`}</p>
              <div
                style={{
                  marginLeft: "18px",
                }}
                role="button"
                onClick={() => {
                  setDateValue(false);
                }}
              >
                <img src={close} alt="icon" height="12px" width="12px" />
              </div>
            </div>
          )}

          {showCalendar && (
            <div className="position-absolute calendar-input_wrapper">
              {/* <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                setDateValue={setDateValue}
                                setShowCalendar={setShowCalendar}

                            /> */}
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDateValue={setDateValue}
                setShowCalendar={setShowCalendar}
              />
            </div>
          )}
        </div>
      </div>

      <Row className="g-4 mb-4 main-dashboard_section">
        <Col sm="12" md="4">
          <div className="analytics-card  withdraw dashboard-card">
            <div className="analytics-card_details h-100 d-flex flex-column justify-content-between flex-wrap">
              <div className="d-flex align-items-cente justify-content-between flex-wrap">
                <h3 className="analytics-card_title ">Earnings (NGN)</h3>
                <div>
                  <button
                    className="dashboard-withdraw_btn btn"
                    onClick={() => {
                      navigate("/dashboard/transactions/withdrawal");
                    }}
                  >
                    Withdraw
                  </button>
                </div>
              </div>
              <div className="d-flex align-tems-center mt-4">
                <div
                  className="pt-"
                  style={{
                    marginRight: "5px",
                    paddingTop: "5.7px",
                  }}
                >
                  <img src={nairaIcon} alt="icon" height="px" />
                </div>
                <h3 className="analytics-card_value mb-0 w-100">
                  {data?.data?.earnings["2"]
                    ? Number(data?.data?.earnings["2"] ?? 0).toLocaleString()
                    : "0.00"}
                </h3>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <p
                  className="analytics-cta  mb-0"
                  role="button"
                  onClick={() => {
                    navigate("/dashboard/transactions");
                  }}
                  style={{
                    marginRight: "10px",
                  }}
                >
                  Details
                </p>

                <div className="">
                  <img src={arrowIcon} alt="icon" height="px" />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="4">
          <div className="analytics-card  withdraw dashboard-card">
            <div className="analytics-card_details h-100 d-flex flex-column justify-content-between flex-wrap">
              <div className="d-flex align-items-cente justify-content-between flex-wrap">
                <h3 className="analytics-card_title ">Earnings (USD)</h3>
                <div>
                  {/* <button
                    disabled
                    className="dashboard-withdraw_btn btn"
                    onClick={() => {
                      navigate("/dashboard/transactions/withdrawal");
                    }}
                  >
                    Withdraw
                  </button> */}
                </div>
              </div>
              <div className="d-flex align-tems-center mt-4">
                <h3 className="analytics-card_value mb-0 w-100">
                  ${" "}
                  {data?.data?.earnings["1"]
                    ? Number(data?.data?.earnings["1"] ?? 0).toLocaleString()
                    : "0.00"}
                </h3>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <p
                  className="analytics-cta  mb-0"
                  role="button"
                  onClick={() => {
                    navigate("/dashboard/transactions");
                  }}
                  style={{
                    marginRight: "10px",
                  }}
                >
                  Details
                </p>

                <div className="">
                  <img src={arrowIcon} alt="icon" height="px" />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="4">
          <div className="analytics-card dashboard-card">
            <div className="analytics-card_details h-100 d-flex flex-column justify-content-between">
              <div className="d-flex align-items-cente justify-content-between">
                <h3 className="analytics-card_title">
                  Total No. of Titles on Genti
                </h3>
              </div>
              <div className="d-flex align-items-center ">
                <h3 className="analytics-card_value mb-0">
                  {data?.data?.total_titles ? data?.data?.total_titles : 0}
                </h3>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="g-4 mb-4">
        <Col sm="12" md="12">
          <div className="second-row analytics-card">
            <div className="analytics-card_details h-100 ">
              <div className="d-flex align-items-center justify-content-between top-releases_wrapper">
                <h3 className="analytics-card_title">Top Releases</h3>
                <div
                  className="d-flex align-items-center justify-content-end"
                  role="button"
                  onClick={() => {
                    navigate("/dashboard/content");
                  }}
                >
                  <p
                    className="analytics-cta  mb-0"
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    View all
                  </p>

                  <div className="">
                    <img src={arrowIcon} alt="icon" height="px" />
                  </div>
                </div>
              </div>
              {releases?.data?.length > 0 ? (
                <>
                  <table className="table mb-0 transaction-table text-white">
                    <thead className="thead-light pb-3"></thead>

                    <tbody className="content-table-body h-100">
                      {releases?.data?.map((el, i) => (
                        <tr>
                          <td className="td-img__container">
                            <div className="d-flex align-items-center">
                              <p className="release-count mb-0">
                                {i + 1 < 10 ? `0${i + 1}` : i + 1}
                              </p>
                              <div
                                className="release-img_wrapper"
                                style={{
                                  borderRadius: "5px",
                                  border: "solid 1px #48494b",
                                }}
                              >
                                <img
                                  src={
                                    el?.image_upload[0]?.location_image ??
                                    noImage
                                  }
                                  alt="release-img"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="td-text__container ms-2 release-name">
                              {el?.title_name}
                            </p>
                          </td>
                          {/* <td className='td-text__container text-center '>{truncate(el?.author_name, 20)}</td>
                                                     <td className='td-text__container text-center '>{el?.paywall === '0' ? 'Inside' : 'Outside'}</td> */}
                          {/* <td className='td-text__container text-center '>{el?.total_time_listened}</td> */}
                          <td className="td-text__container text-r ">{`${formatter(
                            el?.plays
                          )} Plays`}</td>
                          <td className="td-text__container tex-center ">
                            {`${el?.episodes_unlocked || 0} Episodes Unlocked`}
                          </td>
                          <td className="td-text__container tex-center ">
                            {`${el?.titles_unlocked || 0} Titles Unlocked`}
                          </td>
                          <td className="td-text__container tex-center ">
                            {`${el?.coins_used || 0} Coins Used`}
                          </td>
                          <td className="td-text__container text-center">
                            {`${formatter(el?.shares)} Shares`}
                          </td>
                          <td className="td-text__container text-center">
                            <div className="release-rating_wrapper d-flex align-items-center">
                              <div className="rating_icon ">
                                <img src={starIcon} alt="icon" />
                              </div>
                              <p className=" mb-0 pt-1">{el?.avg_rating}</p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <p className="text-center text-white d-flex align-items-center justify-content-center h-75">
                  No content
                </p>
              )}
              <div className="d-none">
                {releases?.data && releases?.data.length > 0 ? (
                  <>
                    {releases?.data?.map((el, i) => (
                      <div
                        key={i}
                        className="top-releases_stats d-flex align-items-center justify-content-between"
                      >
                        <div className="d-flex align-items-center">
                          <p className="release-count mb-0">
                            {i + 1 < 10 ? `0${i + 1}` : i + 1}
                          </p>

                          <div className="release-img_wrapper">
                            <img
                              src={
                                el?.image_upload[0]?.location_image ?? noImage
                              }
                              alt="release-img"
                            />
                          </div>
                          <div className="release-details">
                            <h3 className="release-name ">{el?.title_name}</h3>

                            <div className="release-stat_wrapper d-flex justify-content-between w-100 align-items-center">
                              <p className="stat_value mb-0">{`${formatter(
                                el?.plays
                              )} Plays`}</p>
                              <p className="stat_value mb-0">{`${formatter(
                                el?.favourites
                              )} Favourites`}</p>
                              <p className="stat_value mb-0">{`${formatter(
                                el?.shares
                              )} Shares`}</p>
                              <div className="release-rating_wrapper d-flex align-items-center">
                                <div className="rating_icon ">
                                  <img src={starIcon} alt="icon" />
                                </div>
                                <p className="stat_value mb-0 pt-1">
                                  {el?.avg_rating}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='rating_icon'>
                                             <img src={tIcon} alt='icon' />
                                         </div> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <p className="text-center text-white d-flex align-items-center justify-content-center h-75">
                    No content
                  </p>
                )}
              </div>
            </div>
          </div>
        </Col>
        {/* <Col sm='12' md='3'>
                    <div className="second-row analytics-card ">
                        <div className='analytics-card_details h-100'>
                            <div className='d-flex align-items-cente justify-content-between'>
                                <h3 className='analytics-card_title'>Listener Demographics</h3>

                            </div>
                            <div className='align-items-center chart-wrapper '>

                                {
                                    data?.data?.listeners_demographic ?
                                        <Chart demographic={data?.data?.listeners_demographic} /> :
                                        <EmptyChart />


                                }
                            </div>

                        </div>
                    </div>
                </Col> */}
        {/* <Col sm='12' md='3'>
                    <div className="second-row analytics-card ">
                        <div className='analytics-card_details '>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h3 className='analytics-card_title'>Listener’s Now </h3>
                                <div className='d-flex align-items-center'>
                                    <p className='stock_percentage mb-0'>{data?.data?.listeners_now ? `${data?.data?.listeners_now.increment || data?.data?.listeners_now?.increment === 0 ? '+' : '-'}${data?.data?.listeners_now?.increment || data?.data?.listeners_now?.increment === 0 ? data?.data?.listeners_now?.increment : data?.data?.listeners_now?.decrement}%` : '0%'}</p>
                                    <img src={stockIcon} alt='icon' />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between mb-5' >
                                <h3 className='listener-count w-100 pt-4'>{data?.data?.listeners_now ? data?.data?.listeners_now.total : 0}</h3>
                                <div className='sparkline-wrapper'>
                                    <SparkLine data={data?.data?.listeners_now?.data} />
                                </div>
                            </div>

                            <div className='listener-details-wrapper mt-4'>
                                {
                                    data?.data?.listeners_now?.countries.map((el, i) =>
                                        <div className='d-flex align-items-center justify-content-between listener-details-stats ' key={i}>
                                            <div className='listener-details d-flex align-items-center'>
                                                <div className='listener-flag_wrapper'>
                                                    <img src={findFlagUrlByCountryName(el?.name)} alt='flag' className='listener-flag' />
                                                </div>
                                                <h3 className='mb-0 listener-location'>
                                                    {el?.name}
                                                </h3>
                                            </div>
                                            <h3 className='mb-0 listener-percentage'>
                                                {`${el?.percentage}%`}
                                            </h3>
                                        </div>
                                    )

                                }
                               
                            </div>

                        </div>
                    </div>
                </Col> */}
      </Row>
      <Row className="g-4 mb-4">
        <Col sm="12" md="9">
          <div className="third-row analytics-card">
            <div className="analytics-card_details h-100 ">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="analytics-card_title">Recent Transaction</h3>
                <div className="d-flex align-items-center justify-content-end">
                  <p
                    className="analytics-cta  mb-0"
                    role="button"
                    onClick={() => {
                      navigate("/dashboard/transactions");
                    }}
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    View all
                  </p>

                  <div className="">
                    <img src={arrowIcon} alt="icon" height="px" />
                  </div>
                </div>
              </div>
              <div className="table-responsive h-100">
                {transactions?.data && transactions.data.length > 0 ? (
                  <table className="table mb-0 transaction-table text-white">
                    <thead className="thead-light pb-3">
                      <tr>
                        <th>Type</th>
                        <th>Content Name</th>
                          <th>
                            Episodes <br />
                            Unlocked
                          </th>
                          <th>
                            Titles <br />
                            Unlocked
                          </th>
                        <th>Coins Used</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Reference No.</th>
                        <th>Date</th>
                        <th>Time</th>
                      </tr>
                    </thead>

                    <tbody className="transaction-table-body h-100">
                      {transactions?.data.map((el, i) => (
                        <tr key={i}>
                          <td>{el?.type === 0 ? "Income" : "Withdraw"}</td>
                          <td>
                            {" "}
                            <img
                              src={
                                el?.title?.image_upload?.location_image ??
                                noImage
                              }
                              style={{ maxWidth: "30px" }}
                              alt="release-img"
                            />{" "}
                            {truncate(el?.title?.title, 11)}
                          </td>
                          <td>
                            {" "}
                            {el?.episode_unlocks > 0
                              ? el?.episode_unlocks
                              : "-"}
                          </td>
                          <td>
                            {" "}
                            {el?.titles_unlocks > 0
                              ? el?.titles_unlocks
                              : "-"}
                          </td>
                          <td> {el?.coins_used > 0 ? el?.coins_used : "-"}</td>
                          <td>{`₦${el?.amount.toLocaleString()}`}</td>
                          <td>{el?.description}</td>
                          <td>{el?.reference_number}</td>
                          <td>
                            {moment(el?.created_at).format("Do MMM, YYYY")}
                          </td>
                          <td>
                            {moment(el?.created_at.split("T")[1], [
                              "HH:mm",
                            ]).format("hh:mm a")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center text-white d-flex align-items-center justify-content-center h-75 w-100">
                    No transaction
                  </p>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="3">
          <div className="third-row analytics-card ">
            <div className="analytics-card_details ">
              <div className="d-flex align-items-cente justify-content-between">
                <h3 className="analytics-card_title">
                  Content Completion Rate
                </h3>
              </div>
              <div className="user-progres-wrapper ">
                <Progress completion={data?.data?.content_completion} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    // </AppLayout>
  );
};

export default Dasboard;
