import React, { useState, useEffect, useRef } from "react";
import filter from "../../assets/img/filter-list.svg";
import search from "../../assets/img/searchIcon.svg";
import sort from "../../assets/img/filterTable.svg";
import menu from "../../assets/img/menu.svg";
import select from "../../assets/img/headerselect.svg";
import deleteIcon from "../../assets/img/Delete.svg";
import editIcon from "../../assets/img/Edit.svg";
import activeFilter from "../../assets/img/activeFilter.svg";
import EmptyContent from "./Empty";
import { useQuery } from "react-query";
import Client from "../../client";
import moment from "moment";
import useDebounce from "../../components/Hooks/Debounce";
import { useNavigate } from "react-router-dom";
import truncate from "truncate";
import DeleteContent from "./Delete";
import Pagination from "../../components/Pagination";
import ButtonLoader from "../../components/ButtonLoader";
import t1 from "../../assets/img/no-image.png";
import MobileTile from "./MobileTile";
import showIcon from "../../assets/img/Show.svg";
import { scrollToTop } from "../../utils/helpers";
import { Row, Col } from "reactstrap";

const Content = () => {
  const navigate = useNavigate();
  const content_Ref = useRef(null);

  const [searchValue, setSearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [editContent, setEditContent] = useState(null);
  const [deleteContent, setDeleteContent] = useState(null);
  const [selectedContent, setSelectedDeleteContent] = useState(null);
  const [filterContent, setFilterContent] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(false);
  const [filterByGenre, setFilterByGenre] = useState(false);
  const [filterByLanguage, setFilterByLanguage] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderParam, setOrderParam] = useState(null);

  const toggleDeleteContent = () => {
    setDeleteContent(!deleteContent);
  };

  const {
    data: contents,
    isLoading: loading,
    isFetching,
    isRefetching,
  } = useQuery(
    [
      "content",
      debouncedSearchValue,
      activePage,
      selectedStatus,
      selectedLanguage,
      selectedGenre,
      orderParam,
      order,
    ],

    () =>
      Client.content.content({
        params: debouncedSearchValue,
        status: selectedStatus?.id,
        language: selectedLanguage?.id,
        genre: selectedGenre
          ? selectedGenre.map((el) => el.id).join(",")
          : selectedGenre,
        page: debouncedSearchValue.length > 0 || filterContent ? 1 : activePage,
        orderParam: orderParam,
        order: orderParam ? order : null,
      })
  );

  const { data: language } = useQuery(["content_language"], () =>
    Client.content.content_language()
  );
  const { data: genre } = useQuery(["content_genre"], () =>
    Client.content.content_genre()
  );

  const changeContent = (e) => {
    setActivePage(e);
    content_Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const { data, total, current_page, per_page, metrics } = contents?.data || {};
  const {
    live,
    rejected,
    review,
    total: totalContent,
  } = contents?.metrics || {};

  const { result: language_result } = language?.data || [];
  const { result: genre_result } = genre?.data || [];

  const filterContentByStatus = (val) => {
    setSelectedStatus(val);
    setFilterByStatus(false);
  };

  const filterContentByGenre = (val) => {
    if (selectedGenre === null) {
      setSelectedGenre([val]);
    } else {
      setSelectedGenre((arr) => [...arr, val]);
    }
    setFilterByGenre(false);
  };

  const filterContentByLanguage = (val) => {
    setSelectedLanguage(val);
    setFilterByLanguage(false);
  };

  const resetFilter = () => {
    setSelectedLanguage(null);
    setSelectedGenre(null);
    setSelectedStatus(null);
    setFilterContent(false);
    setFilterByStatus(false);
    setFilterByGenre(false);
    setFilterByLanguage(false);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div ref={content_Ref}>
      <p className="page-title">Content</p>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <div>
          <h4 className="page-details ">Content</h4>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="btn create-content__btn mb-0 text-white"
            onClick={() => {
              navigate("/dashboard/content/upload");
            }}
          >
            Upload Content
          </button>
        </div>
      </div>

      <Row className="g-4 mb-4">
        <Col sm="12" md="3">
          <div className="analytics-card dashboard-card">
            <div className="analytics-card_details h-100 d-flex flex-column justify-content-between">
              <div className="d-flex align-items-cente justify-content-between">
                <h3 className="analytics-card_title">Total No. of Content</h3>
              </div>
              <div className="d-flex align-items-center ">
                <h3 className="analytics-card_value mb-0">
                  {totalContent ?? 0}
                </h3>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="3">
          <div className="analytics-card dashboard-card">
            <div className="analytics-card_details h-100 d-flex flex-column justify-content-between">
              <div className="d-flex align-items-cente justify-content-between">
                <h3 className="analytics-card_title">Live Content </h3>
              </div>
              <div className="d-flex align-items-center ">
                <h3 className="analytics-card_value mb-0">{live ?? 0}</h3>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="3">
          <div className="analytics-card dashboard-card ">
            <div className="analytics-card_details h-100 d-flex flex-column justify-content-between align-items-start flex-column ">
              <div className="d-flex align-items-cente justify-content-between mb-auto">
                <h3 className="analytics-card_title">Content in review</h3>
              </div>
              <div className="d-flex align-items-center mt-auto ">
                <h3 className="analytics-card_value mb-0">{review ?? 0}</h3>
              </div>
            </div>
          </div>
        </Col>
        <Col sm="12" md="3">
          <div className="analytics-card dashboard-card ">
            <div className="analytics-card_details h-100 d-flex flex-column justify-content-between align-items-start flex-column ">
              <div className="d-flex align-items-cente justify-content-between mb-auto">
                <h3 className="analytics-card_title">Rejected Content </h3>
              </div>
              <div className="d-flex align-items-center mt-auto ">
                <h3 className="analytics-card_value mb-0">{rejected ?? 0}</h3>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="reports-card_wrapper">
        <div className="third-row analytics-card content-table withdraw  ">
          {loading || isRefetching || isFetching ? (
            <div
              className="d-flex align-items-center justify-content-center position-absolute"
              style={{
                height: "90%",
                width: "calc(100% - 60px)",
              }}
            >
              <ButtonLoader />
            </div>
          ) : (
            <>
              <div className="table-details__wrapper ">
                <div className="d-block d-lg-flex align-items-center justify-content-between ">
                  <div className="d-block d-lg-flex align-items-center mb-0">
                    <h4 className="table-title pt-2">All Content</h4>
                    <div className="search-content__wrapper d-lg-flex d-none ">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Content"
                        defaultValue={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value.toLowerCase());
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center d-lg-block">
                    <div className="search-content__wrapper d-lg-none d-flex ">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Content"
                        defaultValue={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value.toLowerCase());
                        }}
                      />
                    </div>
                    {filterContent ? (
                      <div
                        className="filtering-active__wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => resetFilter()}
                        role="button"
                      >
                        <img role="button" src={activeFilter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    ) : (
                      <div
                        role="button"
                        className="filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setFilterContent(true);
                        }}
                      >
                        <img src={filter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    )}
                  </div>

                  {/* <div className='filter-icon__wrapper d-flex justify-content-center align-items-center'>
                            <img src={filter} alt='icon' />
                            <p className='filter-content__text pt-1 mb-1'>Filter</p>
                        </div> */}
                </div>
                {filterContent && (
                  <div className="d-block d-md-flex content-filter__ctrl__wrapper mt-3 mt-mt-md-0">
                    <div
                      className=" position-relative"
                      onClick={() => {
                        setFilterContent(true);
                      }}
                    >
                      <div
                        role="button"
                        className="filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper"
                        onClick={() => {
                          setFilterByStatus(!filterByStatus);
                        }}
                      >
                        <p className="filter-content__text filter-type_text pt-1 mb-1">
                          {selectedStatus ? selectedStatus.name : "Status"}
                        </p>
                        <div>
                          {!selectedStatus ? (
                            <img src={select} alt="icon" />
                          ) : (
                            <img
                              src={activeFilter}
                              alt="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedStatus(null);
                                setFilterByStatus(false);
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {filterByStatus && (
                        <div className="position-absolute filter-content-action__cta__wrapper">
                          <p
                            className="filter-select__option "
                            onClick={() => {
                              const data = {
                                name: "Review",
                                id: "review",
                              };
                              filterContentByStatus(data);
                            }}
                          >
                            Review
                          </p>
                          <p
                            className="filter-select__option "
                            onClick={() => {
                              const data = {
                                name: "Live",
                                id: "live",
                              };
                              filterContentByStatus(data);
                            }}
                          >
                            Live
                          </p>
                          <p
                            className="filter-select__option "
                            onClick={() => {
                              const data = {
                                name: "Rejected",
                                id: "rejected",
                              };
                              filterContentByStatus(data);
                            }}
                          >
                            Rejected
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="position-relative">
                      <div
                        role="button"
                        className="filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper genre-select_section"
                        onClick={() => {
                          setFilterByGenre(!filterByGenre);
                        }}
                      >
                        <p className="filter-content__text filter-type_text pt-1 mb-1">
                          {selectedGenre && selectedGenre?.length > 0
                            ? truncate(
                                selectedGenre
                                  ?.map((ele, i) => ele.title)
                                  .join(", "),
                                12
                              )
                            : "Genre"}
                        </p>
                        <div>
                          {!selectedGenre ? (
                            <img src={select} alt="icon" />
                          ) : (
                            <img
                              src={activeFilter}
                              alt="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedGenre(null);
                                setFilterByGenre(false);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {filterByGenre && genre_result && (
                        <div className="position-absolute filter-content-action__cta__wrapper genre_section ">
                          {genre_result?.map((el, i) => (
                            <div
                              key={i}
                              className="d-flex align-items-center filter-select__option"
                            >
                              <input
                                type="checkbox"
                                name="checkbox"
                                className="cstm-checkbox__input filter__checkbox"
                                id={`cstm-checkbox__input-${i}`}
                                role="button"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    filterContentByGenre(el);
                                  } else {
                                    const arr = selectedGenre.filter(
                                      (ele) => ele.title !== el?.title
                                    );
                                    setSelectedGenre(arr);
                                  }
                                }}
                                checked={
                                  selectedGenre
                                    ? selectedGenre.some(
                                        (ele) => ele.title === el?.title
                                      )
                                    : false
                                }
                              />
                              <label
                                className="filter-select__option mb-0"
                                htmlFor={`cstm-checkbox__input-${i}`}
                              >
                                {el?.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="position-relative">
                      <div
                        role="button"
                        className={`filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper language-select_section`}
                        onClick={() => {
                          setFilterByLanguage(!filterByLanguage);
                        }}
                      >
                        <p className="filter-content__text filter-type_text pt-1 mb-1">
                          {selectedLanguage
                            ? selectedLanguage?.name
                            : "Language"}
                        </p>
                        <div>
                          {!selectedLanguage ? (
                            <img src={select} alt="icon" />
                          ) : (
                            <img
                              src={activeFilter}
                              alt="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedLanguage(null);
                                setFilterByLanguage(false);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {filterByLanguage && language_result && (
                        <div
                          className={`position-absolute filter-content-action__cta__wrapper  ${
                            filterByGenre
                              ? "active-filter__genre"
                              : "language-section"
                          }  ${
                            selectedLanguage?.length > 20
                              ? "select_lg"
                              : "select_sm"
                          }`}
                        >
                          {language_result?.map((el, i) => (
                            <div
                              key={i}
                              className="d-flex align-items-center filter-select__option"
                            >
                              <input
                                type="checkbox"
                                name="checkbox"
                                className="cstm-checkbox__input filter__checkbox"
                                role="button"
                                id={`cstm-checkbox__input-${el?.name}`}
                                checked={selectedLanguage?.name === el?.name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    filterContentByLanguage(el);
                                  } else {
                                    setSelectedLanguage("");
                                  }
                                }}
                              />
                              <label
                                htmlFor={`cstm-checkbox__input-${el?.name}`}
                                className="filter-select__option mb-0"
                              >
                                {el?.name}
                              </label>
                            </div>
                          ))}
                          {/* <div className='d-flex align-items-center filter-select__option' >
                                                            <input type="checkbox" name="checkbox" className='cstm-checkbox__input filter__checkbox' role='button' />
                                                            <p className='filter-select__option mb-0'>English</p>

                                                        </div>
                                                        <div className='d-flex align-items-center filter-select__option' >
                                                            <input type="checkbox" name="checkbox" className='cstm-checkbox__input filter__checkbox' role='button' />
                                                            <p className='filter-select__option mb-0'>Yoruba</p>

                                                        </div><div className='d-flex align-items-center filter-select__option' >
                                                            <input type="checkbox" name="checkbox" className='cstm-checkbox__input filter__checkbox' role='button' />
                                                            <p className='filter-select__option mb-0'>Hausa</p>

                                                        </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="analytics-card_details h-100 d-none d-md-block ">
                {data && data?.length > 0 ? (
                  <div className="">
                    <table className="table mb-0 transaction-table text-white">
                      <thead className="thead-light pb-3">
                        <tr>
                          <th>Cover Art</th>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Content Title</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("title");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          <th>Plays</th>
                          <th>Titles Unlocked</th>
                          <th>Episodes Unlocked</th>
                          <th>Coins Used</th>
                          {/* <th>Genre</th> */}
                          {/* <th>Sub Genre.</th> */}
                          {/* <th>Type</th> */}
                          {/* <th>Language</th> */}
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Date Uploaded</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("created_at");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          <th>Status</th>

                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody className="content-table-body">
                        {data.map((el, i) => (
                          <tr key={i}>
                            <td className="td-img__container">
                              <div className="release-img_wrapper">
                                <img
                                  src={
                                    el?.image_upload
                                      ? el?.image_upload?.location_image
                                      : t1
                                  }
                                  alt="release-img"
                                />
                              </div>
                            </td>
                            <td className="td-text__container">
                              {truncate(el?.title, 30)}
                            </td>
                            <td className="td-text__container">{el?.plays}</td>
                            <td>{el?.titles_unlocked}</td>
                            <td className="td-text__container">
                              {el?.episodes_unlocked}
                            </td>
                            <td className="td-text__container">
                              {el?.coins_used}
                            </td>
                            {/* <td className="td-text__container">
                              {truncate(
                                el?.genre
                                  ?.map((ele, i) => ele?.genre?.title)
                                  .join(", "),
                                26
                              )}
                            </td> */}

                            {/* <td className="td-text__container">
                              {el?.type?.title}
                            </td>{" "} */}
                            {/* <td className="td-text__container">
                              {el?.language[0]?.language?.name}
                            </td> */}
                            <td className="td-text__container">
                              {moment(el?.created_at).format("l")}
                            </td>
                            <td>
                              <div
                                className={`content-status ${
                                  el?.status === 1
                                    ? "live"
                                    : el?.status === 2
                                    ? "review"
                                    : "rejected"
                                }`}
                              >
                                {el?.status === 1
                                  ? "Live"
                                  : el?.status === 2
                                  ? "Review"
                                  : "Rejected"}
                              </div>
                            </td>
                            <td className="text-center">
                              <div className="position-relative">
                                <div
                                  className="content-action__wrapper"
                                  role="button"
                                  onClick={() => {
                                    navigate(`/content/details/${el?.id}`);
                                  }}
                                >
                                  <img src={showIcon} alt="icon" />
                                </div>
                                {/* {

                                                                                editContent === i ? <div className='position-absolute content-action__cta__wrapper'>
                                                                                    <div className='d-flex align-items-center content-action__cta'

                                                                                        onClick={() => {
                                                                                            navigate(`/content/edit/${el?.id}`)
                                                                                        }}>
                                                                                        <div className='content-action__icon'>
                                                                                            <img src={editIcon} alt='icon' />
                                                                                        </div>
                                                                                        <p className='content-action_edit_text mb-0'>Edit</p>

                                                                                    </div>
                                                                                    <div className='d-flex align-items-center content-action__cta' onClick={() => {
                                                                                        setSelectedDeleteContent(el?.title)
                                                                                        toggleDeleteContent()

                                                                                    }}>
                                                                                        <div className='content-action__icon'>
                                                                                            <img src={deleteIcon} alt='icon' />
                                                                                        </div>
                                                                                        <p className='content-action_delete_text mb-0'>Delete</p>

                                                                                    </div>
                                                                                </div> : null
                                                                            } */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <div className="d-flex justify-content-between align-items-center cstm-table__head-wrapper">
                      <div className="cstm-table__head">Cover Art</div>
                      <div>
                        <div className="d-flex align-items-center cstm-table__head">
                          <p className="mb-0">Content Title</p>
                          <div className="sort-icon__wrapper">
                            <img src={sort} alt="icon" />
                          </div>
                        </div>
                      </div>
                      <th className="cstm-table__head">Plays</th>
                      <th className="cstm-table__head">Episodes Unlocked</th>
                      <th className="cstm-table__head">Coins Used</th>

                      {/* <div className="cstm-table__head">Genre</div> */}
                      {/* <div className='cstm-table__head'>Sub Genre.</div> */}
                      {/* <div className="cstm-table__head">Type</div>
                      <div className="cstm-table__head">Language</div> */}
                      <div className="cstm-table__head">
                        <div className="d-flex align-items-center cstm-table__head">
                          <p className="mb-0">Date Uploaded</p>
                          <div className="sort-icon__wrapper">
                            <img src={sort} alt="icon" />
                          </div>
                        </div>
                      </div>
                      <div className="cstm-table__head last">Status</div>
                      <div className="cstm-table__head last">Action</div>
                    </div>
                    <EmptyContent />
                  </div>
                )}
              </div>
              <div className="d-block d-md-none">
                {data && data?.length > 0 ? (
                  <>
                    {data.map((el) => (
                      <MobileTile title={el} content={el} />
                    ))}
                  </>
                ) : (
                  <div className="d-flex align-items-center justify-content-center w-100 h-100">
                    <EmptyContent />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {total && total > 10 ? (
        <div className="d-flex justify-content-center align-items-center">
          <Pagination
            onPageChange={changeContent}
            totalCount={total}
            currentPage={current_page}
            pageSize={Number(per_page)}
          />
        </div>
      ) : null}

      <DeleteContent
        toggleDeleteContent={toggleDeleteContent}
        deleteContent={deleteContent}
        selectedContent={selectedContent}
      />
    </div>
  );
};

export default Content;
