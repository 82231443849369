import { AppStore, ColouredLogo, PlayStore } from "./Icons"

export const Footer = () => {

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);

    const handleAppRedirect = (store) => {
        const APP_DEEP_LINK = `https://open.gentimedia.com`; // Genti custom url scheme
        const APP_STORE_URL = 'https://apps.apple.com/app/genti-audio-african-stories/id1626263802';
        const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.app.gentimedia';
      
      
        if (store === 'ios') {
          if (isIOS) {
            // Try to open app first
            window.location.href = APP_DEEP_LINK;
            // Fallback to App Store if app not installed
            setTimeout(() => {
              window.location.href = APP_STORE_URL;
            }, 2000);
          } else {
            window.open(APP_STORE_URL, '_blank');
          }
        }
      
        if (store === 'android') {
          if (isAndroid) {
            // Android Intent URL format
            window.location.href = APP_DEEP_LINK;
            setTimeout(() => {
              window.location.href = PLAY_STORE_URL;
            }, 2000);
          } else {
            window.open(PLAY_STORE_URL, '_blank');
          }
        }
    };

    return (
        <div className="text-white landing-page-footer">
            <div className="landing-page-footer-left">
                <div className="footer-left-content">
                    <div>
                        <ColouredLogo />
                    </div>
                    <p>
                        African Audio Stories, Audiobooks, Podcasts and more. 
                        Mobile App. Download the app and listen for free!
                    </p>
                </div>
            </div>

            <div className="landing-page-footer-right">
                <div className="landing-page-footer-right-quick-links">
                    <h6>Quick Links</h6>
                    <div>
                        <p>Sign Up/Log In</p>
                        <p>FAQs</p>
                        <p>Terms & Conditions</p>
                        <p>Privacy Policy</p>
                    </div>
                </div>

                <div className="landing-page-footer-download">
                    <h6>
                        Download the Genti App today
                    </h6>

                    <div>
                        <div onClick={() => {
                            handleAppRedirect('ios')
                        }}>
                            <AppStore />
                        </div>
                        <div onClick={() => {
                            handleAppRedirect('android')
                        }}>
                            <PlayStore />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}