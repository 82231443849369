import React from "react";
import noImage from "../../../assets/img/no-image.png";
import truncate from "truncate";
import { useNavigate } from "react-router-dom";
import rightArrow from "../../../assets/img/forwardArrow.svg";
import moment from "moment";

function convertSecondsToHHMM(seconds) {
  let h = Math.floor(seconds / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let result = `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}`;

  return result;
}

const MobileTile = ({ content, title, type }) => {
  const navigate = useNavigate();
  return (
    <div
      className="report-mobile-tile"
      onClick={() => {
        navigate(`/dashboard/content/details/${content?.id}`);
      }}
    >
      <div className="report-mobile-tile_header">
        <div className="d-flex  w-100 justify-content-between">
          <div className="d-flex align-items-center">
            <div className="pe-2">
              <img
                className="report-tile__img"
                src={content?.image_upload?.location_image ?? noImage}
              />
            </div>
            <div>
              <h3 className="report-name mb-0">
                {type === "audio_drama" || type === "audio_book"
                  ? content?.episode_name
                  : content?.podcast_title ?? content.title}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="tile-details__wrapper">
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Title</h3>
          <h3 className="tile-details__value">
            {type === "audio_drama" || type === "audio_book"
              ? content?.episode_name
              : content?.podcast_title}
          </h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Type</h3>
          <h3 className="tile-details__value">
            {`${
              title?.type_id === 1 ||
              title?.type_id === 4 ||
              title?.type_id === 5
                ? "Audio Drama"
                : title?.type_id === 1
                ? "Audio Book"
                : "Podcast"
            }`}
          </h3>
        </div>{" "}
        {/* <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Genre</h3>
          <h3 className="tile-details__value">
            {truncate(
              title?.genre?.map((ele, i) => ele?.genre?.title).join(", "),
              40
            )}
          </h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Language</h3>
          <h3 className="tile-details__value">
            {truncate(
              title?.language?.map((ele, i) => ele?.language?.name).join(", "),
              40
            )}
          </h3>
        </div> */}
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Plays</h3>
          <h3 className="tile-details__value">{content?.plays}</h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Coins Used</h3>
          <h3 className="tile-details__value">{content?.coins_used}</h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Episodes Unlocked</h3>
          <h3 className="tile-details__value">{content?.episodes_unlocked}</h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Titles Unlocked</h3>
          <h3 className="tile-details__value">{content?.titles_unlocked}</h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Date Uploaded</h3>
          <h3 className="tile-details__value">
            {moment(content?.created_at).format("l")}
          </h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Status</h3>
          <h3 className="tile-details__value">
            {title?.status === 1
              ? "Live"
              : title?.status === 2
              ? "Review"
              : "Rejected"}
          </h3>
        </div>
        {/* <div className='tile-key-value_wrapper'>
                    <h3 className='tile-details__key'>Date uploaded</h3>
                    <h3 className='tile-details__value'>{moment(content?.created_at).format('L')}</h3>
                </div> */}
      </div>
    </div>
  );
};

export default MobileTile;
