import { useNavigate } from 'react-router-dom'
import peopleBg from '../../assets/img/landing-page-people.png'
import { WhiteLogo, CreatorMic } from './Icons'

export const Section1 = () => {

    const navigate = useNavigate();

    return (
        <div className="section-1-landing-page text-white">
            <div className='landing-page-sect1-bg' >
                <img src={peopleBg} alt='bg-img' className='' />
            </div>

            <div className='sect1-landing-content'>
                <div className='landing-nav'>
                    <WhiteLogo />
                    <button onClick={() => {
                        navigate('/auth/login')
                    }}>
                        Sign In <span>&#x203A;</span>
                    </button>
                </div>
                <div className='landing-sect1-main'>
                    <div className='top-button'>
                        <CreatorMic />
                        <span>Create, Sell, Protect Your Work & Earn</span>
                    </div>
                    <div className='text-section'>
                        <p className='title'>
                            Monetize Your Audio Content & Engage Your Community
                        </p>
                        <p>
                            Turn your audiobooks, poetry, comedy specials, and audio 
                            plays into earnings—in any language. Upload seamlessly, 
                            protect your IP, track earnings in real time, and seamless 
                            withdrawals—all while delivering a fantastic listening 
                            experience to your community.
                        </p>
                    </div>
                    <div className='bottom-buttons'>
                        <button className='button1' onClick={() => {
                            navigate('/auth/register')
                        }}>
                            Sign up and start telling
                        </button>
                        <button className='button2' onClick={() => {
                            navigate('/auth/login')
                        }}>
                            Log in to your account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}