import React, { useState } from 'react'
import AuthLayout from '../../layout/Auth'
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidation } from '../../utils/form_validation';
import { useMutation } from 'react-query';
import Client from '../../client'
import { toast } from 'react-toastify';
import ButtonLoader from '../../components/ButtonLoader';
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';


const Login = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const togglePassword = () => {
        setShowPassword(!showPassword)
    }

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(loginValidation),
    });


    const { mutate: loginUser, isLoading } = useMutation(Client.auth.login, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
                // navigate('/auth/verify-login')
            } else {
                Cookies.set("CREATOR_AUTH_TOKEN", data.data.access_token)
                Cookies.set("CREATOR_CURRENT_USER", JSON.stringify(data.data.user_detail))

                reset();
                navigate('/dashboard')
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
            // navigate('/auth/verify-login')

        }
    })

    const onSubmitHandler = (data) => {
        // Cookies.set("GENTI_LOGIN_USER_EMAIL", data.email)
        loginUser(data)
    };


    return (
        <AuthLayout>
            <form className='auth-form' onSubmit={handleSubmit(onSubmitHandler)}>
                <div className='mb-3'>
                    <h3 className='form-title sign-text'>Sign In</h3>
                </div>
                <div className='mb-4'>
                    {
                        errors.email ? <p className='text-danger auth-label mb-0'>{errors.email?.message}</p> : <label className='auth-label'>Email Address</label>
                    }
                    <input
                        // type='email'
                        placeholder='account@email.com'
                        className={`auth-input form-control w-100 ${errors.email ? 'border-danger' : ''} `}
                        {...register("email")}
                    />
                </div>
                <div className='mb-2'>
                    {errors.password ? <p className='text-danger auth-label mb-0'>{errors.password?.message}</p> : <label className='auth-label' tabIndex={0}>Password</label>}
                    <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>

                        <input
                            type={`${showPassword ? 'text' : 'password'}`}
                            className={`auth-input form-control w-100 ${errors.password ? 'border-danger' : ''}`}
                            {...register("password")}
                        />
                        <div className='visibility-icon position-absolute' onClick={togglePassword} role='button'>
                            {`${showPassword ? 'HIDE' : 'SHOW'}`}
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end w-100 forgot-password-link'>
                    <Link to={'/auth/forgot-password'} className='auth-link '>Forgot Password?</Link>
                </div>

                <div className='sign-in-btn'>
                    <button className='btn auth-btn w-100 ' type='submit' disabled={isLoading}>
                        {
                            isLoading ?
                                <ButtonLoader /> :
                                'Sign in'
                        }


                    </button>
                </div>

                <div>
                    <p className='auth-text'>
                        New to Genti?  <Link to={'/auth/register'} className='auth-link '>Create Account</Link>
                    </p>
                </div>

            </form>
        </AuthLayout>
    )
}

export default Login