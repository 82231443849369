import React, { useState } from "react";
import backArrow from "../../../assets/img/backArrow.svg";
import RSS from "./RSS";
import MP3 from "./MP3";
import { useNavigate } from "react-router-dom";

const Upload = () => {
  const navigate = useNavigate();
  const [contentType, setContentType] = useState("");
  return (
    <>
      {contentType === "" && (
        <div>
          <p className="page-title">
            Content/
            <span className="page-sub-title text-white">Upload Content</span>
          </p>

          <div className="mb-4 d-flex align-items-center">
            <div
              style={{
                marginRight: "9px",
              }}
              role="button"
              onClick={() => {
                navigate("/dashboard/content");
              }}
            >
              <img src={backArrow} alt="icon" />
            </div>
            <h4 className="page-details mb-0">Upload Content</h4>
          </div>

          <div className="third-row analytics-card content-table withdraw">
            <div className="d-block d-lg-flex align-items-center content-select__wrapper ">
              <p className="content-select__text mb-0">
                What is the format of your existing audio?
              </p>
              <div className="content-select_form ">
                <label className="radio_label">
                  <input
                    value={"RSS"}
                    className="content-select_input"
                    name="content-type"
                    type="radio"
                    onChange={(e) => {
                      setContentType(e.target.value);
                    }}
                  />
                  <span className="content-type__select">RSS</span>
                </label>
                <label className="radio_label">
                  <input
                    value={"MP3"}
                    className="content-select_input"
                    name="content-type"
                    type="radio"
                    onChange={(e) => {
                      setContentType(e.target.value);
                    }}
                  />
                  <span className="content-type__select">MP3 file</span>
                </label>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      )}
      {contentType === "RSS" && <RSS />}
      {contentType === "MP3" && <MP3 />}
    </>
  );
};

export default Upload;
