import AbsoluteHeadPhone from '../../assets/img/absolute-head-phone.png'
import AshleyTestimony from '../../assets/img/testimony-ashley.png'
import Anton1 from '../../assets/img/Anton1.png'
import Anton2 from '../../assets/img/Anton2.png'
import { Redirect, Telegram, WhatsApp } from './Icons'
import EpisodeGroup from '../../assets/img/episode-group.png'
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useEffect, useRef, useState } from 'react'

const testimonyData = [
    {
        name: "Ashley Cooper",
        image: AshleyTestimony,
        role: "Author",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
    },
    {
        name: "Anton de Swardt",
        image: Anton1,
        role: "Narrator",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
    },
    {
        name: "Anton de Swardt",
        image: Anton2,
        role: "Author",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
    },
    {
        name: "Anton de Swardt",
        image: Anton2,
        role: "Author",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut"
    }
]

export const Section4 = () => {

    const carouselRef = useRef();
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);

    const checkScrollPosition = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setIsAtStart(scrollLeft <= 0);
            setIsAtEnd(Math.ceil(scrollLeft + clientWidth) >= scrollWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkScrollPosition);
        }
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    const handleCarouselScroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = carouselRef.current.clientWidth * 0.8;
            const currentScroll = carouselRef.current.scrollLeft;
            
            carouselRef.current.scrollTo({
                left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             entries.forEach((entry) => {
    //                 if (entry.target === carouselRef.current.firstChild) {
    //                     setIsAtStart(entry.isIntersecting);
    //                 }
    //                 if (entry.target === carouselRef.current.lastChild) {
    //                     setIsAtEnd(entry.isIntersecting);
    //                 }
    //             });
    //         },
    //         { root: carouselRef.current, threshold: 0.5 }
    //     );
    
    //     const children = carouselRef.current?.children || [];
    //     if (children.length) {
    //         observer.observe(children[0]); // First card
    //         observer.observe(children[children.length - 1]); // Last card
    //     }
    
    //     return () => observer.disconnect();
    // }, []);
    

    return (
        <div className="landing-page-section4">
            {/* <div className='absolute-head-phone'>
                <img src={AbsoluteHeadPhone} alt="Abs Head Phone" />
            </div> */}

            <div className='landing-page-sect4-head'>
                <h2>What Our Creators Say</h2>

                <div className="carousel-controls">
                    <button 
                        className={`carousel-control-btn ${isAtStart ? 'disabled' : ''}`}
                        onClick={() => handleCarouselScroll('left')}
                        disabled={isAtStart}
                        aria-label="Scroll left"
                    >
                        <FaArrowLeft size={20} color={isAtStart ? "#E2E2E2" : "#5F42E2"} />
                    </button>
                    <button 
                        className={`carousel-control-btn ${isAtEnd ? 'disabled' : ''}`}
                        onClick={() => handleCarouselScroll('right')}
                        disabled={isAtEnd}
                        aria-label="Scroll right"
                    >
                        <FaArrowRight size={20} color={isAtEnd ? "#E2E2E2" : "#5F42E2"} />
                    </button>
                </div>
            </div>

            {/* Important: To make this thing scrollable */}

            <div className='sect4-testimony-cards' ref={carouselRef}>
                {
                    testimonyData.map((testimony, i) => (
                        <div key={i} className='sect4-testimony-card'>
                            <p>{testimony.text}</p>
                            <div className='sect4-testimony-card-bottom'>
                                <img src={testimony.image} alt={testimony.name.split(' ')[0]} />
                                <div>
                                    <p className='testimony-card-name'>{testimony.name}</p>
                                    <p>{testimony.role}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='sect4-support-section'>
                <div className='support-left-section'>
                    <div className='support-request'>
                        <h1>Need Support or Have Suggestions?</h1>
                        <p>We're building this for you - help us make it better!</p>
                    </div>
                    <div className='social-links'>
                        <div>
                            <h6>Email</h6>
                            <p>support@gentimedia.com</p>
                        </div>
                        <div>
                            <h6>Join our community</h6>
                            <p>
                                <span><WhatsApp /></span>
                                {/* <span>WhatsApp</span> */}
                                <span><Redirect /></span>
                            </p>
                            <p>
                                <span><Telegram /></span>
                                <span>Telegram</span>
                                <span><Redirect /></span>
                            </p>
                        </div>
                        <div>
                            <h6>WhatsApp Channel</h6>
                            <p>
                                <span><WhatsApp /></span>
                                {/* <span>WhatsApp</span> */}
                                <span><Redirect /></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='support-right-section'>
                    <img src={EpisodeGroup} alt="Episodes" />
                </div>
            </div>
        </div>
    )
}
{/* <div className='sect4-testimony-card'>
    <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut labore et dolore magna 
        aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat. 
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
        sed do eiusmod tempor incididunt ut
    </p>
    <div className='sect4-testimony-card-bottom'>
        <img src={AshleyTestimony} alt="Ashley Testimony" />
        <div>
            <p className='testimony-card-name'>Ashley Cooper</p>
            <p>Author</p>
        </div>
    </div>
</div> */}